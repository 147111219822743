






































import { Component, Vue } from 'vue-property-decorator';
import { OutputUserDTO } from '@/api/api';
import DeleteButton from '@/components/DeleteButton.vue';
import { EventBus } from '@/main';
import { updateObjectList } from '@/utils';
import { formatApiRole } from '@/types/ApiRole';

@Component({
  components: {
    DeleteButton,
  },
})
export default class UsersList extends Vue {
  public headers = [
    {
      text: 'ID',
      sortable: true,
      value: 'id',
      align: 'left',
    },
    {
      text: 'Email',
      sortable: true,
      value: 'email',
      align: 'left',
    },
    {
      text: 'First Name',
      sortable: true,
      value: 'first_name',
      align: 'left',
    },
    {
      text: 'Last Name',
      sortable: true,
      value: 'last_name',
      align: 'left',
    },
    {
      text: 'Username',
      sortable: true,
      value: 'username',
      align: 'left',
    },
    {
      text: 'Role',
      sortable: true,
      value: 'roles',
      align: 'left',
    },
    {
      text: 'Actions',
      sortable: false,
      align: 'center',
      value: 'actions',
    },
  ];

  public users: OutputUserDTO[]|null = null;

  public async created() {
    EventBus.$on('user', (data) => {
      this.users = updateObjectList(this.users, data);
    });
  }

  public async mounted() {
    this.getUsers();
  }

  public async getUsers() {
    const data = await this.$store.dispatch('users/getAll');
    this.users = data ?? [];
  }

  public async deleteUser(id) {
    await this.$store.dispatch('users/remove', { userId: id })
  }

  public formatApiRole = formatApiRole;

  get loading() {
    return this.users === null;
  }
}
