var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{staticClass:"ma-3 pa-3"},[_c('v-card-title',{attrs:{"primary-title":""}},[_c('div',{staticClass:"headline primary--text"},[_vm._v("Users")]),_c('v-spacer'),_c('v-btn',{staticClass:"float-right py-0",attrs:{"color":"primary","to":"/main/admin/users/create"}},[_vm._v("Create User")])],1),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.users || [],"loading":_vm.loading},scopedSlots:_vm._u([{key:"item.roles",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatApiRole(item.roles[0]))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"icon":"","to":{
              name: 'main-admin-users-edit',
              params: { userId: item.id },
            }}},[_c('v-icon',[_vm._v("edit")])],1),_c('DeleteButton',{attrs:{"itemID":item.id,"deleteFunction":_vm.deleteUser}})]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }